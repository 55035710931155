<template>
	<v-card class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" max-width="800" rounded="xl">
		<v-card-title>{{ $t('gdrive.gDriveAccounts') }}</v-card-title>
		<v-card-text class="text-justify">
			<v-list v-cloak subheader>
				<v-list-item v-for="(gDriveEmail, index) in gDriveEmails" :key="index">
					<v-list-item-avatar>
						<v-icon>mdi-google-drive</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>{{ gDriveEmail.email }}</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<v-dialog v-model="dialog" persistent max-width="350">
							<template v-slot:activator="{ on }">
								<v-btn icon color="error" v-on="on">
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</template>
							<v-card rounded="xl">
								<v-card-title>
									<span class="headline">{{ $t('settings.areYouSure') }}</span>
								</v-card-title>
								<v-card-text>
									TODO-You will no longer have access to the courses associated to this account
								</v-card-text>
								<v-card-actions>
									<v-spacer />
									<v-btn color="blue darken-1" text @click="dialog = false">No</v-btn>
									<v-btn color="error darken-1" text @click="callRemoveGDriveEmail(gDriveEmail)">{{ $t('settings.delete') }}</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-avatar>
						<v-icon>mdi-account-plus</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-form ref="form" v-model="valid">
							<v-text-field
								v-model="newGDriveEmail"
								:label="$t('gdrive.newGDriveEmail')"
								:counter="emailMaxLength"
								:rules="rules.newGDriveEmail"
								required
								outlined
							/>
						</v-form>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-action>
						<v-btn
							:color="addGDriveEmailButtonColor"
							:loading="action.started && !action.finished"
							:outlined="action.started && !action.finished"
							:disabled="action.started"
							@click="callAddGDriveEmail()"
						>
							<v-icon left>
								mdi-plus-circle-outline
							</v-icon>
							{{ addGDriveEmailButtonText }}
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'UserGoogleDriveEmails',
	props: {
		gDriveEmails: {
			type: Array,
			default() {
				return []
			}
		}
	},
	data() {
		return {
			valid: false,
			dialog: false,
			newGDriveEmail: '',
			rules: {
				newGDriveEmail: [
					(v) => !!v || 'Email is required.',
					(v) => v.indexOf('@') !== 0 || 'Email should have a username.',
					(v) => v.includes('@') || 'Email should include an @ symbol.',
					(v) => v.includes('.') || 'Email should include a period symbol.',
					(v) => v.indexOf('.') <= v.length - 3 || 'Email should contain a valid domain extension.',
					(v) => /.+@.+\..+/.test(v) || 'Email must be valid',
					(v) => (v && v.length <= this.emailMaxLength) || `Name must be less than ${this.emailMaxLength} characters`
				]
			},
			addGDriveEmailButtonText: this.$t('settings.add'),
			action: {
				started: false,
				finished: false
			}
		}
	},
	computed: {
		addGDriveEmailButtonColor() {
			if (this.action.finished && this.action.success) {
				return 'success'
			} else if (this.action.finished && !this.action.success) {
				return 'warning'
			} else {
				return 'primary'
			}
		},
		emailMaxLength() {
			return 255
		}
	},
	created() {},
	methods: {
		callRemoveGDriveEmail(gDriveEmail) {
			this.removeGDriveEmail(gDriveEmail.id)
			this.dialog = false
		},
		callAddGDriveEmail() {
			this.$refs.form.validate()
			if (!this.valid) return
			if (!this.action.started) {
				this.action.started = true
				this.addGDriveEmailButtonText = this.$t('search.loading')
				this.addGDriveEmail(this.newGDriveEmail)
					.then((data) => {
						if (data.success) {
							this.addGDriveEmailButtonText = this.$t('settings.done')
						} else {
							this.action.started = false
							this.addGDriveEmailButtonText = this.$t('settings.tryAgain')
						}
					})
					.catch(() => {
						this.addGDriveEmailButtonText = 'Error'
					})
					.then(() => {
						this.action.finished = true
					})
			}
		},
		...mapActions('users', ['addGDriveEmail', 'removeGDriveEmail'])
	}
}
</script>
